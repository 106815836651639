import React from "react";
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const VTPage = () => (
  <Layout>
    <Seo
      title="Verhaltenstherapie Berlin Buch | Privatpraxis f. Psychotherapie"
      description="Individuell auf Sie abgestimmte Verhaltenstherapie in Berlin. Kognitive Verhaltenstherapie, Schematherapie, Traumatherapie, Achtsamkeit & emotionsfokussierte Therapie."
    />

    <div className="relative">
      <div className="w-full h-96">
        <StaticImage
          className="w-full h-full"
          src="../assets/images/hero-ueber-mich.jpg"
          alt=""
        />
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="lg:text-4xl">Kognitive Verhaltens&shy;therapie</h1>
            {/* <div>Diese Seite befindet sich im derzeit im Aufbau. Bei Fragen wenden Sie sich gerne an mich</div> */}
          </div>
        </div>
      </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
      <p>
        Häufig entwickeln sich seelische Erkrankungen aus schwierigen
        Lebenssituationen, denen wir mit erlernten Verhaltensmustern begegnen.
        Die damit verbundenen ungünstigen Gedanken und Überzeugungen (kognitive
        Schemata) haben ihren Ursprung meist in der Kindheit. Diese
        Lernerfahrungen helfen uns oft langfristig nicht weiter, sondern lösen
        eine Art Teufelskreis aus. Ein erster wichtiger Schritt im Rahmen einer
        kognitiven Verhaltenstherapie ist es, diesen Lernerfahrungen
        nachzuspüren und sie verstehen zu lernen. So können wir gemeinsam
        alternative Strategien erarbeiten, die Ihnen im Hier und Jetzt zu
        positiveren Gedanken und Gefühlen verhelfen, Ihre Lebenszufriedenheit
        und Selbstwirksamkeit steigern.
      </p>
      <p>
        In drei bis fünf Probesitzungen entwickeln wir zunächst gemeinsam ein
        Modell zur Beschreibung und Erklärung Ihrer Problematik. Auch werden wir
        Ihre Lebensgeschichte und Kindheit in der Probatorik besprechen, dabei
        ist die Frage wichtig, welche der vergangenen Erfahrungen möglicherweise
        für Ihre jetzige Situation relevant sein könnten. Daraus entwickeln wir
        konkrete, individuelle Ziele für die Therapie, an denen wir uns im
        Verlauf orientieren. So können Sie Schritt für Schritt den Weg heraus
        aus dem Teufelskreis, hinein in einen neuen Lebensabschnitt gehen.
      </p>
      <p>
        Die kognitive Verhaltenstherapie gilt als erfolgreiches,
        evidenzbasiertes Behandlungskonzept bei psychischen Erkrankungen. Die
        Therapieform fokussiert auf die gegenseitige Einflussnahme von Denken,
        Fühlen und Handeln. Denn Gedanken, Gefühle und Verhalten sind es
        letztlich, die uns ausmachen. Die Behandlung setzt auf allen drei Ebenen
        an, um eine ganzheitliche Arbeit zu ermöglichen. Dabei ist es nie zu
        spät für eine Behandlung, auch im hohen Alter sind wir zu
        Weiterentwicklung fähig und verdienen es, dass seelisches Leid gemindert
        wird.
      </p>
      <p>
        Das Behandlungsspektrum umfasst alle psychischen Erkrankungen, häufig
        sind beispielsweise Depressionen, Angsterkrankungen, akute Krisen (z.B.:
        Trennung, Jobverlust, Trauer, Krankheit), Schlafstörungen,
        Zwangsstörungen, Substanzmissbrauch und Abhängigkeit, posttraumatische
        Belastungsstörungen sowie sexuelle Funktionsstörungen.
      </p>
      <p>
        Die Sitzungsanzahl variiert je nach Ausprägung der seelischen
        Erkrankung, wobei Ihre Mitentscheidung selbstverständlich ist. Eine
        Kurzzeittherapie umfasst beispielsweise bis zu 24 Sitzungen, eine
        Langzeittherapie bis zu 60 Sitzungen, diese können bei Bedarf auch auf
        80 Sitzungen verlängert werden.
      </p>
    </div>
  </Layout>
);

export default VTPage;
